import { useRootLoaderData } from '~/root';
import { PrimaryNavLinkButton } from './primary-nav-link-button';
import Modal from './modal';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function CreateAccountModal({ open, onClose }: Props) {
  const { enterpriseMemberDetails } = useRootLoaderData();

  // when user is a team plan account, and has been assigned the billing-only role, they cannot create an organization and should show this modal
  // if (!enterpriseMemberDetails) {
  //   return null;
  // }

  return (
    <Modal
      isOpen={open}
      title="You cannot create an organization with this corporate account."
      className="max-w-[600px]"
      onCancel={onClose}
    >
      <div className="flex-[0 1] overflow-y-auto">
        <div className="flex flex-1 flex-col">
          {enterpriseMemberDetails && (
            <>
              <h2 className="m-0 text-sm font-normal leading-[23px] tracking-[-0.25px] text-[#000000d9]">
                Your Insomnia account is tied to the enterprise corporate account. Please ask the owner of the
                enterprise billing to create one for you.
              </h2>
              <h2 className="m-0 mt-4 text-sm font-normal leading-[23px] tracking-[-0.25px] text-[#000000d9]">
                You can also create your own personal account on Insomnia, which will be separate from the current
                account and doesn't have restrictions.
              </h2>
            </>
          )}
          {enterpriseMemberDetails === null && (
            <>
              <h2 className="m-0 text-sm font-normal leading-[23px] tracking-[-0.25px] text-[#000000d9]">
                Your Insomnia account is tied to the enterprise corporate account.
              </h2>
              <h2 className="m-0 mt-4 text-sm font-normal leading-[23px] tracking-[-0.25px] text-[#000000d9]">
                You can create your own personal account on Insomnia, which will be separate from the current account
                and doesn't have restrictions.
              </h2>
            </>
          )}
          <div className="mx-auto my-[0px] flex w-full flex-col items-center justify-center">
            <PrimaryNavLinkButton className="mt-4 h-10 w-[200px]" to="/app/authorize" target="__blank">
              Create personal account
            </PrimaryNavLinkButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
